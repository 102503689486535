import React from 'react'
import styled from 'styled-components'

const Banner = styled.div`
  width: 100%;
  text-align: center;
    background: yellow;
    padding: 10px 0;
`

export default function TopBanner() {
  return (
    <Banner>
      Don’t miss your chance to earn extra rewards! 👉{' '}
      <a href="https://x.com/Swopfi/status/1877312846138093833?s=19">Details here</a>
    </Banner>
  )
}
